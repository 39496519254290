import React from 'react'
import styled from 'styled-components'
import RoundCircle from '../images/Tick_inactive_30.svg'
import CheckCircle from '../images/Tick_active_30.svg'
import ErrorCircle from '../images/Circle_Error_30.svg'

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  background: #FFFFFF;
  border: 1px solid ${props => props.status === 'success' || props.status === null ? '#005564' : '#E84A5E'};
  /* Form Box Shadow */
  box-shadow: 0px 2px 8px rgba(79, 119, 169, 0.12);
  border-radius: 9px;
`
const InputText = styled.input`
  
  text-align: left;
  width: 100%;
  border-style: none;
  &:focus {
    outline: none;
    letter-spacing: 4px;
  }
  font-family: Arial;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 4px;
  // padding-top: 8px;
  :focus::-webkit-input-placeholder {
    opacity: 1;
    letter-spacing: 4px;
    font-size: 24px;
    line-height: 24px;
  }
  ::-webkit-input-placeholder {
    // position:relative;
    // top:3px;
    letter-spacing: 4px;
    font-size: 24px;
    line-height: 24px;
  }
`

const AccessKey = ({ status = null, accesskeyValue, onChange, ...props }) => {
  return (
    <InputContainer status={status}>
      <div style={{
        backgroundColor: '#F5FAFE',
        display: 'flex', alignItems: 'center', margin: '-10px -10px',
        height: '48px', borderRadius: '9px 0px 0px 9px',
        fontFamily: 'Arial', fontStyle: 'normal', fontWeight: 600, fontSize: '24px',
        lineHeight: '24px', padding: '0px 15px 0px 20px', letterSpacing: '4px', color: '#00424D'
      }}>P-</div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

        <div style={{ width: "100%", padding: '0px 20px' }} >
          <InputText type="text" placeholder="" value={accesskeyValue} onChange={onChange}{...props} />
        </div>
      </div>
    </InputContainer>
  )
}

export default AccessKey